import useCategoriesBrands from '@hooks/useCategoriesBrands.main'
import useWebsiteId from '@hooks/useWebsiteId'
import {Fragment} from 'react'
import {ScrollToSignature} from '..'
import styles from '../CategoryNavBar/styles.module.css'
import useCategoriesMinimal from '../CategoryNavBar/useCategoriesMinimal.main'
import BrandBar from './BrandBar'

export interface Props {
  onClick: () => void
  scrollTo: ScrollToSignature
}

export default function Categories(props: Props) {
  const websiteId = useWebsiteId()
  const categoriesResult = useCategoriesBrands(websiteId)
  const categoriesMinimal = useCategoriesMinimal()
  const visibleCategorySet = new Set(categoriesMinimal?.map(category => category._id) || [])

  const categories = categoriesResult?.items?.filter(category =>
    visibleCategorySet.has(category._id),
  )

  if (!categories) return null

  let lastBrandId = null

  return (
    <div className={styles.brandsContainer}>
      {categories.map(category => {
        let brand = null
        if (category.brand && category.brand._id !== lastBrandId) {
          brand = category.brand
          lastBrandId = brand._id
        }

        const brandContent = brand ? (
          <BrandBar
            brand={brand}
            onClick={() => {
              props.onClick()
              props.scrollTo(category._id, {getOffset: ogOffset => ogOffset - 100})
            }}
          />
        ) : null

        if (!category.brand && lastBrandId) {
          lastBrandId = null
        }
        return <Fragment key={category._id}>{brandContent}</Fragment>
      })}
    </div>
  )
}
